<template>
  <b-container class="bg-dark fontStandard">
    <b-form>
      <b-row>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-input-group>
              <b-form-datepicker
                v-model="supervisor.create_date"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
              <b-input-group-prepend>
                <b-input-group-text>Création</b-input-group-text>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-form-datepicker
                v-model="supervisor.update_date"
                dark
                no-flip
                hide-header
                readonly
                disabled
              />
              <b-input-group-prepend>
                <b-input-group-text>Modification</b-input-group-text>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>URL</b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_url"
                class="form-control"
                placeholder="Entrer l'url de destination"
                type="text"
                v-model="supervisor.url"
                maxlength="512"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Foreground</b-input-group-text>
              </b-input-group-prepend>
              <b-input-group-prepend>
                <b-input-group-text
                  :style="{ background: supervisor.foreground }"
                ></b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_foreground"
                class="form-control"
                placeholder="Couleur de premier plan"
                type="text"
                v-model="supervisor.foreground"
                maxlength="25"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Background</b-input-group-text>
              </b-input-group-prepend>
              <b-input-group-prepend>
                <b-input-group-text
                  :style="{ background: supervisor.background }"
                ></b-input-group-text>
              </b-input-group-prepend>
              <b-input
                id="supervisor_background"
                class="form-control"
                placeholder="Couleur d'arrière plan"
                type="text"
                v-model="supervisor.background"
                maxlength="25"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Qualité</b-input-group-text>
              </b-input-group-prepend>
              <select class="form-control" v-model="supervisor.quality">
                <option value="L">Basse</option>
                <option value="M">Moyenne</option>
                <option value="Q">Bonne</option>
                <option value="H">Haute</option>
              </select>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-button
              @click="toggle_qr_code()"
              :variant="supervisor.enable === '1' ? 'secondary' : 'primary'"
              block
            >
              <i :class="['fas fa-qrcode']"></i>
              <strong>
                &nbsp;{{ supervisor.enable === "1" ? "Désactiver" : "Activer" }}
                ce QrCode
              </strong>
            </b-button>
          </b-form-group>
        </b-col>
        <!--  -->
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <QrCode
            :value="supervisor.code"
            :color="supervisor.foreground"
            image="png"
            :background="supervisor.background"
            className=" text-center"
            :size="350"
            :quality="supervisor.quality"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button variant="warning" block @click="delete_qr_code">
              <strong>Supprimer ce QR Code</strong>
            </b-button>
          </b-form-group>
        </b-col>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button
              @click="testLink(supervisor.code)"
              :variant="'info'"
              block
            >
              <strong> Tester cette url </strong>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button
              type="submit"
              variant="success"
              @click="modify_qr_code"
              block
            >
              <i class="fas fa-user-edit" /> Modifier
            </b-button>
          </b-form-group>
        </b-col>
        <b-col class="mx-auto text-white" cols="12" lg="6">
          <b-form-group>
            <b-button
              type="submit"
              variant="danger"
              @click="$root.$emit('bv::hide::modal', 'view-modal')"
              block
            >
              <i class="fas fa-times" /> Fermer cette fenetre
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    QrCode: () => import("@/components/QrCode"),
  },
  mixins: [Form],
  props: {
    items: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      supervisor: this.items,
    };
  },
  beforeDestroy() {
    this.supervisor = undefined;
  },
  computed: {
    ...mapState(["account", "axios"]),
    ...mapGetters([]),
  },
  methods: {
    async toggle_qr_code() {
      const supervisor_enable = this.supervisor.enable === "1" ? "0" : "1";

      const uri =
        supervisor_enable === "1" ? "qr/codes/enable" : "qr/codes/disable";

      await this.$root.axios("put", uri, {
        id: this.supervisor.id,
      });

      const axios = this.account.axios_data;
      if (axios && axios.api_response === "successfull_change") {
        this.supervisor.enable = supervisor_enable;
      }

      this.completeCommit();
    },
    testLink(url = null) {
      window.open(url, "_blank");
    },
    async modify_qr_code(e) {
      e.preventDefault();

      const Obj = {
        id: this.supervisor.id,
        url: this.supervisor.url,
        foreground: this.supervisor.foreground,
        background: this.supervisor.background,
        quality: this.supervisor.quality,
      };

      await this.$root.axios("put", "qr/codes/change", Obj);

      this.completeCommit();
    },

    async delete_qr_code(e) {
      e.preventDefault();

      this.$bvModal
        .msgBoxConfirm(
          "Vous souhaitez supprimer ce QrCode ?, cette action est irreversible ",
          {
            title: "Suppression QR Code",
            size: "md",
            buttonSize: "md",

            okTitle: "Je confirme",
            cancelTitle: "Non j'annule",
            footerClass: "p-2",

            noStacking: true,

            headerBgVariant: "dark",
            bodyBgVariant: "dark",
            footerBgVariant: "dark",
            cancelVariant: "danger",
            okVariant: "success",
            headerCloseVariant: "light",
            headerTextVariant: "light",
            headerBorderVariant: "dark",
            footerBorderVariant: "dark",

            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const Obj = {
              id: this.supervisor.id,
            };

            await this.$root.axios("delete", "qr/codes/delete", Obj);

            this.completeCommit();
          } else
            this.$root.showToast(
              `Opération annulée`,
              `Ouf, nous avons frolé la catastrophe.`,
              "info",
              2000
            );
        })
        .catch((err) => {
          this.$root.internalError();
        });
    },

    completeCommit() {
      const axios = this.account.axios_data;

      if (
        axios &&
        axios.api_response === "successfull_change" &&
        axios.check_input.length === 0
      ) {
        this.$root.showToast(
          `Modification`,
          `Modifications effectuées`,
          "info",
          2000
        );

        // this.$parent.$parent.$parent.$refs.table.refresh();
        // TO DO recharger les données dans le modal ?
      } else if (
        axios &&
        axios.api_response === "successfull_delete" &&
        axios.check_input.length === 0
      ) {
        this.$root.showToast(
          `suppression`,
          `Suppression effectuées`,
          "info",
          2000
        );

        this.$root.$emit("bv::hide::modal", "view-modal");
        // this.$parent.$parent.$parent.$refs.table.refresh();
        // TO DO recharger les données dans le modal ?
      }
    },
  },
};
</script>
